import axios from "axios";

import {
  UserUtil
} from "@/utils/user";

import {
  ApiCode
} from "@/api/api-code";

import {
  EZHud
} from "@/utils/ezkit/ez-hud";
import router from "@/router";

// axios.defaults.timeout = 30000; //响应时间
axios.defaults.baseURL = "http://localhost:9003"; //配置接口地址

// import { storage } from "@/utils/storage"

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    //在发送请求之前做某件事
    //携带请求头
    let token = 2; // storage.getCookie("access_token")
    // console.log("Authorization:" + token);
    //下面两种方式都行
    // config.headers.accessToken = token;
    try {
      let user = UserUtil.get();
      if (user.token && user.token != "") {
        config.headers["Authorization"] = user.token;
      }
    } catch (e) {
      console.log(e);
    }

    return config;
  },
  (error) => {
    console.log("错误的传参");
    return Promise.reject(error);
  }
);

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    console.log(">>>>>> ", res);
    //对响应数据做些事
    let data = res.data;
    // || res.status == 200
    if (data.status == 1) {
      return Promise.resolve(data);
    } else if (
      res.status >= 200 &&
      res.status < 300 &&
      res.data instanceof ArrayBuffer
    ) {
      return Promise.resolve(data);
    } else {
      if (data.status == 401) {
        EZHud.error("登录异常，请重新登录");
        UserUtil.remove();
        router.replace("/sign-in");
        return;
      }
      let errMsg = {
        status: data.status,
        message: "",
        data: data.data,
      };
      try {
        errMsg.message = ApiCode[data.status + ""] || data.message;
      } catch (e) {
        errMsg.message = data.message;
      }
      return Promise.reject(errMsg);
    }
  },
  (error) => {
    let errMsg = {
      status: error.status,
      message: ApiCode[error.status + ""] || "网络无法连接，请确认网络通畅",
    };
    if (axios.isCancel(error)) {
      // console.log('>>>>>>----->>>>>>>', error)
      errMsg.message = error.message || "CANCEL";
    }
    // console.log(" ---- catch error : ", error)
    return Promise.reject(errMsg);
  }
);

//返回一个Promise(发送post请求)
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(
        (response) => {
          typeof resolve === "function" && resolve(response);
        },
        (err) => {
          typeof reject === "function" && reject(err);
        }
      )
      .catch((error) => {
        typeof reject === "function" && reject(error);
      });
  });
}

//返回一个Promise(发送delete请求)
export function del(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then(
        (response) => {
          typeof resolve === "function" && resolve(response);
        },
        (err) => {
          typeof reject === "function" && reject(err);
        }
      )
      .catch((error) => {
        typeof reject === "function" && reject(error);
      });
  });
}

//返回一个Promise(发送put请求)
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(
        (response) => {
          typeof resolve === "function" && resolve(response);
        },
        (err) => {
          typeof reject === "function" && reject(err);
        }
      )
      .catch((error) => {
        typeof reject === "function" && reject(error);
      });
  });
}

// 返回一个Promise(发送get请求)
export function get(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: param,
      })
      .then(
        (response) => {
          typeof resolve === "function" && resolve(response);
        },
        (err) => {
          typeof reject === "function" && reject(err);
        }
      )
      .catch((error) => {
        typeof reject === "function" && reject(error);
      });
  });
}

// 上传
export function upload(url, formData, progress) {

  return new Promise((resolve, reject) => {
    axios({
        method: "post",
        url: url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          let num = ((event.loaded / event.total) * 100) | 0; //百分比
          typeof progress === "function" && progress(num, event);
        },
        data: formData,
      })
      .then((response) => {
          typeof resolve === "function" && resolve(response);
        },
        (err) => {
          typeof reject === "function" && reject(err);
        })
      .catch((error) => {
        typeof reject === "function" && reject(error);
      });
  });
}

export default {
  post,
  del,
  put,
  get,
  upload
};