import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import IdleVue from "idle-vue";

// 引入 elementui
import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css'

import "./assets/css/element-variables.scss";

Vue.use(ElementUI);

// 全局样式
import "./assets/css/common.scss";
import "./assets/css/element-extend/el-button.css";
import "./assets/css/element-extend/el-dialog.scss";
import "./assets/css/iconfont.css";
// import "./assets/css/element-extend.css"
// import "./assets/font/iconfont.css"

import {
  sys_def
} from "./utils/sys_def";
import {
  EZHud
} from "./utils/ezkit/ez-hud";
import {
  EZDate
} from "@/utils/ezkit/ez-date";
import {
  UserUtil
} from "./utils/user";

Vue.config.productionTip = false;
Vue.prototype.$sys = sys_def;
Vue.prototype.$ezhud = EZHud;
Vue.prototype.$ezdate = EZDate;
Vue.prototype.$user = UserUtil;
Vue.prototype.$numberFmt = (num, decimals = 2) => {
  if(isNaN(num)) {
    num = 0
  }
  num = Math.round(num * 100) / 100
  num = num.toFixed(decimals)
  console.log(`>>>>>>>>>>> ${num}  -  ${parseFloat(num)} <<<<<<<<<<<<`)
  return parseFloat(num)
};

// echart 图表
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 剪切板
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// 注册全局组件
import BasePage from "@/components/base-page/index.vue";
Vue.component("BasePage", BasePage);

import BackBar from "@/components/back-bar.vue";
Vue.component("BackBar", BackBar);

import TableHeaderColumn from "@/components/table-header-column/index.vue";
Vue.component("TableHeaderColumn", TableHeaderColumn);

import EzInput from "@/components/ez-input/index.vue";
Vue.component("EzInput", EzInput);

import NoAuthBoard from "@/components/no-auth-board/index.js";
Vue.use(NoAuthBoard);

import UserAvatar from "@/components/user-avatar.vue";
Vue.component("UserAvatar", UserAvatar);


import StudentAttendanceCell from "@/pages/student/components/student-attendance-cell.vue";
Vue.component("StudentAttendanceCell", StudentAttendanceCell);


Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});

// 空闲状态检测
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1000 * 60 * 60, // 60分钟,
});


// 中英文i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n) // 通过插件的形式挂载

const i18n = new VueI18n({  
  locale: navigator.language == 'zh-CN' ? 'cn' : 'en', //系统语言中英文选择，没有则默认中文 
   formatFallbackMessages: true,  
    messages: {   
     'cn': require('@/static/i18n/cn'),   // 中文语言包   
     'en': require('@/static/i18n/en')    // 英文语言包  
    }}
  )


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  onIdle() {
    this.$alert('You have been away for too long, the page is about to refresh', '', {
      confirmButtonText: 'Ok',
      callback: action => {
        window.location.reload();
      }
    })
  },
  onActive() {
    console.log('>>>>>>>>> onActive')
  }
}).$mount("#app");